@font-face {
  font-family: 'Avenir Next Cyr Medium';
  src: url('/assets/fonts/avenir/AvenirNextCyr-Medium.eot');
  src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
    url('/assets/fonts/avenir/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/avenir/AvenirNextCyr-Medium.woff2') format('woff2'),
    url('/assets/fonts/avenir/AvenirNextCyr-Medium.woff') format('woff'),
    url('/assets/fonts/avenir/AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Bold';
  src: url('/assets/fonts/avenir/AvenirNextCyr-Bold.eot');
  src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'),
    url('/assets/fonts/avenir/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/avenir/AvenirNextCyr-Bold.woff2') format('woff2'),
    url('/assets/fonts/avenir/AvenirNextCyr-Bold.woff') format('woff'),
    url('/assets/fonts/avenir/AvenirNextCyr-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Heavy';
  src: url('/assets/fonts/avenir/AvenirNextCyr-Heavy.eot');
  src: local('Avenir Next Cyr Heavy'), local('AvenirNextCyr-Heavy'),
    url('/assets/fonts/avenir/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/avenir/AvenirNextCyr-Heavy.woff2') format('woff2'),
    url('/assets/fonts/avenir/AvenirNextCyr-Heavy.woff') format('woff'),
    url('/assets/fonts/avenir/AvenirNextCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Insane Technology Bandwagon Regular';
  src: url('/assets/fonts/insane-technology-bandwagon/insanetechnologybandwagon-reg-webfont.eot');
  src: url('/assets/fonts/insane-technology-bandwagon/insanetechnologybandwagon-reg-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/insane-technology-bandwagon/insanetechnologybandwagon-reg-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/insane-technology-bandwagon/insanetechnologybandwagon-reg-webfont.woff')
      format('woff'),
    url('/assets/fonts/insane-technology-bandwagon/insanetechnologybandwagon-reg-webfont.ttf')
      format('truetype'),
    url('/assets/fonts/insane-technology-bandwagon/insanetechnologybandwagon-reg-webfont.svg#insane_technology_bandwagonRg')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
