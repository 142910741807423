@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'styles/index.scss';
@import 'app/directives/cropped.directive.scss';

html {
  height: 100%;
  background-color: black;
}
