@layer utilities {
  .glowing-text {
    -webkit-text-shadow: rgba(255, 255, 255, 0.7) 0px 0px 4px;
    -moz-text-shadow: rgba(255, 255, 255, 0.7) 0px 0px 4px;
    text-shadow: rgba(255, 255, 255, 0.7) 0px 0px 4px;
  }

  @media (min-width: 768px) {
    .glowing-text {
      -webkit-text-shadow: rgba(255, 255, 255, 0.7) 0px 0px 8px;
      -moz-text-shadow: rgba(255, 255, 255, 0.7) 0px 0px 8px;
      text-shadow: rgba(255, 255, 255, 0.7) 0px 0px 8px;
    }
  }
}
@layer utilities {
  .glowing-block {
    -webkit-filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.7));
    -moz-filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.7));
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.7));
  }

  @media (min-width: 768px) {
    .glowing-block {
      -webkit-filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.7));
      -moz-filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.7));
      filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.7));
    }
  }
}

.no-shadow {
  text-shadow: none;
}
