.cropped {
  --all: 0px;
  --b: white;
  --b-width: 2px;
  --text-color: #fff;
  color: var(--text-color);
  border: var(--b-width) solid var(--b);
  background: linear-gradient(
        45deg,
        var(--b) 0 calc(var(--bottom-left, var(--all)) + var(--b-width)),
        transparent 0
      )
      bottom left/50% 50%,
    linear-gradient(
        -45deg,
        var(--b) 0 calc(var(--bottom-right, var(--all)) + var(--b-width)),
        transparent 0
      )
      bottom right/50% 50%,
    linear-gradient(
        135deg,
        var(--b) 0 calc(var(--top-left, var(--all)) + var(--b-width)),
        transparent 0
      )
      top left/50% 50%,
    linear-gradient(
        -135deg,
        var(--b) 0 calc(var(--top-right, var(--all)) + var(--b-width)),
        transparent 0
      )
      top right/50% 50%,
    var(--img, #000);
  background-origin: border-box;
  background-repeat: no-repeat;
  -webkit-mask: linear-gradient(45deg, transparent 0 var(--bottom-left, var(--all)), #fff 0) bottom
      left,
    linear-gradient(-45deg, transparent 0 var(--bottom-right, var(--all)), #fff 0) bottom right,
    linear-gradient(135deg, transparent 0 var(--top-left, var(--all)), #fff 0) top left,
    linear-gradient(-135deg, transparent 0 var(--top-right, var(--all)), #fff 0) top right;
  mask: linear-gradient(45deg, transparent 0 var(--bottom-left, var(--all)), #fff 0) bottom left,
    linear-gradient(-45deg, transparent 0 var(--bottom-right, var(--all)), #fff 0) bottom right,
    linear-gradient(135deg, transparent 0 var(--top-left, var(--all)), #fff 0) top left,
    linear-gradient(-135deg, transparent 0 var(--top-right, var(--all)), #fff 0) top right;
  -webkit-mask-size: 50% 50%;
  mask-size: 50% 50%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  transition: none;
}

@media (max-width: 455px) {
  #share-btn, #payment-btn, #question-btn {
      padding-left: .5rem;
      padding-right: .5rem;
  }
}
